.decision-tree-container {
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  position: relative;

  .decision-tree-content {
    position: relative;
    display: inline-block;
    cursor: grab;

    .decision-tree-group {
      display: flex;
      align-items: flex-start;

      .decision-tree-group-container {
        display: inline-flex;
        flex-direction: column;
      }

      .decision-tree-group-column {

        .column-item {
          font-size: 14px;
          width: 250px;
          padding: 10px;
          margin-bottom: 10px;
          margin-right: 10px;
          background-color: $gray-efe;
          border: 1px solid $gray-efe;
          &.selected {
            border-color: $blue-9c9;
          }

          &.current {
            background-color: $chat-gray;
          }

          &.preselected {
            border-color: $orange-f0a !important;
          }

          &.proposition {
            .proposition-message {
              textarea {
                width: 100%;
                height: 130px;
                cursor: grab;
              }
            }
          }

          &.outcome {
            cursor: pointer;
          }

          & .control-panel {
            text-align: right;
          }

          & .el-button {
            color: $blue-9c9;
          }
        }
      }
    }

    .add-root-placeholder {
      width: 250px;
      padding: 10px;
      color: $blue-9c9;
      cursor: pointer;
    }
  }
}

