
div.dataTables_wrapper
{
    &.no-footer {
        border-bottom: 1px solid #111;
    }
    thead th, thead td {
        padding: 10px 18px;
        border-bottom: 1px solid #111;
    }

    td {
        padding:10px;
        margin:0;
        vertical-align: middle;
        line-height:1;
    }

    .dataTables_filter {
        input[type="search"] {
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
    }

    .dt-buttons,
    .dataTables_paginate {
        flex: 1;
    }

    .dataTables_info {
        padding:0;
    }
    .dataTables_paginate {
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        .paginate_button {
            background-color:$muave;
            padding:5px 10px;
            margin:0;
            border: 1px solid $white !important;
            border-top:none !important;
            border-bottom:none !important;
            border-left:none !important;
            color:$white !important;
            text-decoration:none !important;
            &.previous {
                border-radius:5px 0 0 5px;
                border:none;
            }
            &.next {
                border-radius:0 5px 5px 0;
                border:none;
            }
            &.current {
                //color: $orange-f0a !important;
                //background: $muave;

                cursor: default;
                color: #666 !important;
                background: transparent;
                box-shadow: none;
            }
            &:hover, &.current:hover {
                background: $muave-hover;
                color: $orange-f0a !important;
                border: 1px solid $white;
            }
            &.next:hover {
                border: none;
            }
        }
    }

    .btn.btn-soft-delete {
        background-color: $orange;
    }
}

