@charset "UTF-8";

div.dataTables_wrapper.no-footer {
  border-bottom: 1px solid #111;
}

div.dataTables_wrapper thead th,
div.dataTables_wrapper thead td {
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

div.dataTables_wrapper td {
  padding: 10px;
  margin: 0;
  vertical-align: middle;
  line-height: 1;
}

div.dataTables_wrapper .dataTables_filter input[type=search] {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

div.dataTables_wrapper .dt-buttons,
div.dataTables_wrapper .dataTables_paginate {
  flex: 1;
}

div.dataTables_wrapper .dataTables_info {
  padding: 0;
}

div.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

div.dataTables_wrapper .dataTables_paginate .paginate_button {
  background-color: #716eff;
  padding: 5px 10px;
  margin: 0;
  border: 1px solid #fff !important;
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  color: #fff !important;
  text-decoration: none !important;
}

div.dataTables_wrapper .dataTables_paginate .paginate_button.previous {
  border-radius: 5px 0 0 5px;
  border: none;
}

div.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  border-radius: 0 5px 5px 0;
  border: none;
}

div.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  cursor: default;
  color: #666 !important;
  background: transparent;
  box-shadow: none;
}

div.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
div.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #4e4bcc;
  color: #f0a27a !important;
  border: 1px solid #fff;
}

div.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
  border: none;
}

div.dataTables_wrapper .btn.btn-soft-delete {
  background-color: #fd7e14;
}

body,
html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  height: 100%;
}

.form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.checkbox-ui,
.login-page form .form-group .checkbox-ui,
.success-msg-page form .form-group .checkbox-ui,
.forgot-password-page form .form-group .checkbox-ui,
.reset-password-page form .form-group .checkbox-ui {
  position: relative;
  display: inline-block;
}

.checkbox-ui input[type=checkbox],
.login-page form .form-group .checkbox-ui input[type=checkbox],
.success-msg-page form .form-group .checkbox-ui input[type=checkbox],
.forgot-password-page form .form-group .checkbox-ui input[type=checkbox],
.reset-password-page form .form-group .checkbox-ui input[type=checkbox],
.checkbox-ui input[type=radio],
.login-page form .form-group .checkbox-ui input[type=radio],
.success-msg-page form .form-group .checkbox-ui input[type=radio],
.forgot-password-page form .form-group .checkbox-ui input[type=radio],
.reset-password-page form .form-group .checkbox-ui input[type=radio] {
  position: absolute;
  left: 0px;
  z-index: 99;
  width: 20px;
  height: 20px;
  float: left;
  cursor: pointer;
  opacity: 0;
}

.checkbox-ui input[type=checkbox]:checked + label:after,
.checkbox-ui input[type=radio]:checked + label:after {
  font-size: 13px;
  font-size: 0.8125rem;
  content: "";
  font-family: "fontawesome";
  position: absolute;
  left: 4px;
  top: 0.4px;
  color: #545454;
}

.checkbox-ui label,
.login-page form .form-group .checkbox-ui label,
.success-msg-page form .form-group .checkbox-ui label,
.forgot-password-page form .form-group .checkbox-ui label,
.reset-password-page form .form-group .checkbox-ui label {
  float: left;
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #545454;
  text-transform: capitalize;
  padding-left: 30px;
  cursor: pointer;
}

.checkbox-ui label:before,
.login-page form .form-group .checkbox-ui label:before,
.success-msg-page form .form-group .checkbox-ui label:before,
.forgot-password-page form .form-group .checkbox-ui label:before,
.reset-password-page form .form-group .checkbox-ui label:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #545454;
  position: absolute;
  left: 0px;
  top: 0;
}

.btn.focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.error-mgs {
  font-size: 12px;
  font-size: 0.75rem;
  color: red;
}

.custom-btn,
.login-page form .submit-btn,
.success-msg-page form .submit-btn,
.forgot-password-page form .submit-btn,
.reset-password-page form .submit-btn {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  background: #3d3a7f;
  padding: 16px;
  border-radius: 5px;
  letter-spacing: 0.8px;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  text-decoration: none;
}

.custom-btn a,
.login-page form .submit-btn a,
.success-msg-page form .submit-btn a,
.forgot-password-page form .submit-btn a,
.reset-password-page form .submit-btn a {
  color: #fff;
}

.custom-btn i,
.login-page form .submit-btn i,
.success-msg-page form .submit-btn i,
.forgot-password-page form .submit-btn i,
.reset-password-page form .submit-btn i {
  font-size: 18px;
  font-size: 1.125rem;
  padding-right: 5px;
  position: relative;
  top: 2px;
}

.custom-btn:hover,
.login-page form .submit-btn:hover,
.success-msg-page form .submit-btn:hover,
.forgot-password-page form .submit-btn:hover,
.reset-password-page form .submit-btn:hover {
  background-color: #6e6eff;
}

.main-dashboard {
  width: 100%;
}

@media (min-width: 992px) {
  .main-dashboard {
    padding-left: 300px;
  }
}

.main-dashboard .page-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 65px;
}

@media (max-width: 991.98px) {
  .main-dashboard .page-wrapper {
    padding-top: 75px;
  }
}

@media (max-width: 575.98px) {
  .main-dashboard .page-wrapper {
    padding-top: 130px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-dashboard .page-wrapper .page-header {
  padding: 35px 0 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

@media (max-width: 575.98px) {
  .main-dashboard .page-wrapper .page-header {
    display: inline-block;
  }

  .main-dashboard .page-wrapper .page-header .col {
    display: inline-block;
    width: 100%;
  }
}

.main-dashboard .page-wrapper .page-header h1 {
  margin: 0px;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #212529;
  letter-spacing: 0.5px;
}

@media (max-width: 575.98px) {
  .main-dashboard .page-wrapper .page-header h1 {
    margin-bottom: 15px;
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.main-dashboard .page-wrapper .page-header .col:nth-child(2) {
  text-align: right;
}

@media (max-width: 575.98px) {
  .main-dashboard .page-wrapper .page-header .col:nth-child(2) {
    text-align: left;
  }
}

.btn-create,
.btn-purple {
  color: #fff;
  background-color: #716eff;
}

.btn-create:hover,
.btn-purple:hover {
  color: #fff;
  background-color: #4e4bcc;
}

.btn-primary {
  background-color: #007bff;
  outline: none;
}

.btn-primary:hover {
  background-color: #6e6eff;
}

.btn.btn-default {
  background-color: #eaeaea;
}

a:hover {
  color: #6e6eff;
}

.btn {
  outline: none !important;
  border: none;
}

.scrollbar-inner .scroll-element .scroll-element_track {
  background-color: #c9c7f7;
  opacity: 1;
  filter: alpha(opacity=100);
}

.scrollbar-inner .scroll-element .scroll-bar {
  background-color: #3f3b9a;
  opacity: 1;
  filter: alpha(opacity=100);
}

.scrollbar-inner .scroll-element:hover .scroll-bar {
  background-color: #3f3b9a;
}

select {
  cursor: pointer;
}

i {
  font-family: "fontawesome";
}

header.sticky-top {
  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 1000;
  background-color: #e9ecef;
}

@media (min-width: 992px) {
  header.sticky-top {
    padding-left: 300px;
  }
}

@media (min-width: 992px) {
  header.sticky-top .logo {
    display: none;
  }
}

@media (max-width: 575.98px) {
  header.sticky-top .logo {
    width: 100%;
    text-align: center;
    background: #007bff;
  }
}

header.sticky-top .logo img {
  height: 55px;
  padding: 6px 16px;
  float: left;
}

@media (max-width: 575.98px) {
  header.sticky-top .logo img {
    float: none;
  }
}

header.sticky-top .logo span {
  max-width: 160px;
  word-wrap: break-word;
  display: inline-block;
  white-space: normal;
  font-size: 15px;
  color: #212529;
  padding: 5px 0;
}

@media (max-width: 575.98px) {
  header.sticky-top .logo span {
    text-align: left;
    max-width: none;
    color: #fff;
  }
}

header.sticky-top ul {
  padding: 0;
  margin: 0;
  margin-right: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: auto;
  float: right;
}

header.sticky-top ul li {
  display: inline-block;
  padding: 10px 20px;
}

header.sticky-top ul li .nav-profile-img {
  float: left;
}

header.sticky-top ul li .nav-profile-img .user-face {
  width: 37px;
  height: 37px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  float: left;
  border-radius: 100%;
  position: relative;
}

header.sticky-top ul li .nav-profile-img .user-face .status {
  font-size: 10px;
  position: absolute;
  top: 43%;
  right: -2px;
}

header.sticky-top ul li .nav-profile-img .user-face .status.online {
  color: #28a745;
}

header.sticky-top ul li .nav-profile-img .user-face .status.offline {
  color: #6c757d;
}

header.sticky-top ul li .nav-profile-img p {
  display: inline-block;
  margin: 0;
  padding: 5px 10px;
  padding-right: 0px;
}

header.sticky-top ul li.notification-msg {
  padding-left: 0px;
}

header.sticky-top ul li.notification-msg .dropdown-menu {
  padding: 0px;
}

header.sticky-top ul li.notification-msg .dropdown-menu li {
  display: block;
  border-bottom: 1px solid #ced4da;
}

header.sticky-top ul li.notification-msg a[data-toggle=dropdown] {
  position: relative;
  padding: 11px 0px;
}

header.sticky-top ul li.notification-msg a i {
  color: #ced4da;
}

header.sticky-top ul li.notification-msg a .status-alert {
  color: #fd7e14;
  font-size: 8px;
  position: absolute;
  top: 10px;
  right: 0;
}

header.sticky-top ul li.user-menu {
  border-left: 1px solid #ced4da;
}

header.sticky-top ul li.user-menu a i {
  font-size: 25px;
  color: #ced4da;
}

header.sticky-top ul li .dropdown-menu {
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  margin: 0;
  min-width: 260px;
  top: 10px !important;
  overflow: hidden;
  z-index: 9999;
}

header.sticky-top ul li .dropdown-menu a {
  font-size: 13px;
  font-size: 0.8125rem;
  display: inline-block;
  width: 100%;
  padding: 15px 20px;
  text-decoration: none;
  color: #212529;
}

header.sticky-top ul li .dropdown-menu a:hover {
  background-color: #e9ecef;
}

header.sticky-top ul li .dropdown-menu a i {
  font-size: 14px;
  font-size: 0.875rem;
  color: #212529;
  margin-right: 5px;
}

header.sticky-top ul li .dropdown-menu ul {
  margin: 0px;
}

header.sticky-top ul li .dropdown-menu ul li {
  width: 100%;
  padding: 0;
}

header.sticky-top ul li .dropdown-menu ul li.not-head {
  padding: 15px 20px;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  font-size: 14px;
  font-size: 0.875rem;
}

header.sticky-top ul li .dropdown-menu ul li.not-footer {
  padding: 0;
  text-align: center;
  background-color: #fff;
}

header.sticky-top ul li .dropdown-menu ul li a {
  display: table;
}

header.sticky-top ul li .dropdown-menu ul li a .media-left {
  display: table-cell;
  vertical-align: top;
  padding-right: 15px;
}

header.sticky-top ul li .dropdown-menu ul li a .media-left .user-img {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
}

header.sticky-top ul li .dropdown-menu ul li a .media-body {
  display: table-cell;
  vertical-align: top;
}

header.sticky-top ul li .dropdown-menu ul li a .media-body .block {
  width: 100%;
  display: inline-block;
}

header.sticky-top ul li .dropdown-menu ul li a .media-body .block-time {
  width: 100%;
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
}

.login-page,
.success-msg-page,
.forgot-password-page,
.reset-password-page {
  max-width: 525px;
  padding: 15px;
  margin: auto;
  padding-top: 30px;
  padding-top: 2.0833333333vw;
}

@media (max-width: 767px) {
  .login-page,
  .success-msg-page,
  .forgot-password-page,
  .reset-password-page {
    padding: 5px;
    padding-top: 20px;
    padding-top: 5.3333333333vw;
  }
}

.login-page .logo,
.success-msg-page .logo,
.forgot-password-page .logo,
.reset-password-page .logo {
  text-align: center;
}

.login-page .logo img,
.success-msg-page .logo img,
.forgot-password-page .logo img,
.reset-password-page .logo img {
  max-width: 200px;
}

.login-page .logo p,
.success-msg-page .logo p,
.forgot-password-page .logo p,
.reset-password-page .logo p {
  font-size: 16px;
  font-size: 1rem;
  margin-top: 15px;
  margin-top: 1.0416666667vw;
  margin-bottom: 30px;
  margin-bottom: 2.0833333333vw;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
  color: #212529;
}

@media (max-width: 767px) {
  .login-page .logo p,
  .success-msg-page .logo p,
  .forgot-password-page .logo p,
  .reset-password-page .logo p {
    margin-top: 10px;
    margin-top: 2.6666666667vw;
    margin-bottom: 20px;
    margin-bottom: 5.3333333333vw;
  }
}

.login-page .form-heading,
.success-msg-page .form-heading,
.forgot-password-page .form-heading,
.reset-password-page .form-heading {
  text-align: center;
}

.login-page .form-heading h3,
.success-msg-page .form-heading h3,
.forgot-password-page .form-heading h3,
.reset-password-page .form-heading h3 {
  font-size: 25px;
  font-size: 1.5625rem;
  margin: 0px;
  margin-bottom: 10px;
  margin-bottom: 0.6944444444vw;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
  color: #212529;
}

@media (max-width: 767px) {
  .login-page .form-heading h3,
  .success-msg-page .form-heading h3,
  .forgot-password-page .form-heading h3,
  .reset-password-page .form-heading h3 {
    margin-bottom: 10px;
    margin-bottom: 2.6666666667vw;
  }
}

.login-page .form-heading p,
.success-msg-page .form-heading p,
.forgot-password-page .form-heading p,
.reset-password-page .form-heading p {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0px;
  margin-top: 5px;
  margin-top: 0.3472222222vw;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #6c757d;
}

@media (max-width: 767px) {
  .login-page .form-heading p,
  .success-msg-page .form-heading p,
  .forgot-password-page .form-heading p,
  .reset-password-page .form-heading p {
    margin-bottom: 5px;
    margin-bottom: 1.3333333333vw;
  }
}

.login-page form,
.success-msg-page form,
.forgot-password-page form,
.reset-password-page form {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 40px;
  padding: 2.7777777778vw;
  margin-top: 40px;
  margin-top: 2.7777777778vw;
  margin-bottom: 40px;
  margin-bottom: 2.7777777778vw;
}

@media (max-width: 767px) {
  .login-page form,
  .success-msg-page form,
  .forgot-password-page form,
  .reset-password-page form {
    padding: 25px;
    padding: 6.6666666667vw;
    margin-top: 15px;
    margin-top: 4vw;
    margin-bottom: 15px;
    margin-bottom: 4vw;
  }
}

.login-page form .form-group label,
.success-msg-page form .form-group label,
.forgot-password-page form .form-group label,
.reset-password-page form .form-group label {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #212529;
  text-transform: capitalize;
}

.login-page form .form-group input.form-control,
.success-msg-page form .form-group input.form-control,
.forgot-password-page form .form-group input.form-control,
.reset-password-page form .form-group input.form-control {
  font-size: 13px;
  font-size: 0.8125rem;
  border-radius: 0px;
  border: none;
  border-bottom: 2px solid #adb5bd;
  background: none;
  padding: 0px;
}

.login-page form .form-group input.form-control.placeholder,
.success-msg-page form .form-group input.form-control.placeholder,
.forgot-password-page form .form-group input.form-control.placeholder,
.reset-password-page form .form-group input.form-control.placeholder {
  color: #545454;
  opacity: 1;
}

.login-page form .form-group input.form-control:-moz-placeholder,
.success-msg-page form .form-group input.form-control:-moz-placeholder,
.forgot-password-page form .form-group input.form-control:-moz-placeholder,
.reset-password-page form .form-group input.form-control:-moz-placeholder {
  color: #545454;
  opacity: 1;
}

.login-page form .form-group input.form-control::-moz-placeholder,
.success-msg-page form .form-group input.form-control::-moz-placeholder,
.forgot-password-page form .form-group input.form-control::-moz-placeholder,
.reset-password-page form .form-group input.form-control::-moz-placeholder {
  color: #545454;
  opacity: 1;
}

.login-page form .form-group input.form-control:-ms-input-placeholder,
.success-msg-page form .form-group input.form-control:-ms-input-placeholder,
.forgot-password-page form .form-group input.form-control:-ms-input-placeholder,
.reset-password-page form .form-group input.form-control:-ms-input-placeholder {
  color: #545454;
  opacity: 1;
}

.login-page form .form-group input.form-control::-webkit-input-placeholder,
.success-msg-page form .form-group input.form-control::-webkit-input-placeholder,
.forgot-password-page form .form-group input.form-control::-webkit-input-placeholder,
.reset-password-page form .form-group input.form-control::-webkit-input-placeholder {
  color: #545454;
  opacity: 1;
}

.login-page form .form-group input.form-control:focus,
.success-msg-page form .form-group input.form-control:focus,
.forgot-password-page form .form-group input.form-control:focus,
.reset-password-page form .form-group input.form-control:focus {
  border-bottom-color: #3d3a7f;
  -webkit-box-shadow: 0px 2px 0px rgba(114, 103, 233, 0.09);
  -moz-box-shadow: 0px 2px 0px rgba(114, 103, 233, 0.09);
  box-shadow: 0px 2px 0px rgba(114, 103, 233, 0.09);
}

.login-page form .form-group input.form-control:focus.placeholder,
.success-msg-page form .form-group input.form-control:focus.placeholder,
.forgot-password-page form .form-group input.form-control:focus.placeholder,
.reset-password-page form .form-group input.form-control:focus.placeholder {
  color: transparent;
  opacity: 0;
}

.login-page form .form-group input.form-control:focus:-moz-placeholder,
.success-msg-page form .form-group input.form-control:focus:-moz-placeholder,
.forgot-password-page form .form-group input.form-control:focus:-moz-placeholder,
.reset-password-page form .form-group input.form-control:focus:-moz-placeholder {
  color: transparent;
  opacity: 0;
}

.login-page form .form-group input.form-control:focus::-moz-placeholder,
.success-msg-page form .form-group input.form-control:focus::-moz-placeholder,
.forgot-password-page form .form-group input.form-control:focus::-moz-placeholder,
.reset-password-page form .form-group input.form-control:focus::-moz-placeholder {
  color: transparent;
  opacity: 0;
}

.login-page form .form-group input.form-control:focus:-ms-input-placeholder,
.success-msg-page form .form-group input.form-control:focus:-ms-input-placeholder,
.forgot-password-page form .form-group input.form-control:focus:-ms-input-placeholder,
.reset-password-page form .form-group input.form-control:focus:-ms-input-placeholder {
  color: transparent;
  opacity: 0;
}

.login-page form .form-group input.form-control:focus::-webkit-input-placeholder,
.success-msg-page form .form-group input.form-control:focus::-webkit-input-placeholder,
.forgot-password-page form .form-group input.form-control:focus::-webkit-input-placeholder,
.reset-password-page form .form-group input.form-control:focus::-webkit-input-placeholder {
  color: transparent;
  opacity: 0;
}

@media (min-width: 768px) {
  .login-page form .form-group .forgot-link,
  .success-msg-page form .form-group .forgot-link,
  .forgot-password-page form .form-group .forgot-link,
  .reset-password-page form .form-group .forgot-link,
  .login-page form .form-group .have-password,
  .success-msg-page form .form-group .have-password,
  .forgot-password-page form .form-group .have-password,
  .reset-password-page form .form-group .have-password {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .login-page form .form-group .forgot-link,
  .success-msg-page form .form-group .forgot-link,
  .forgot-password-page form .form-group .forgot-link,
  .reset-password-page form .form-group .forgot-link,
  .login-page form .form-group .have-password,
  .success-msg-page form .form-group .have-password,
  .forgot-password-page form .form-group .have-password,
  .reset-password-page form .form-group .have-password {
    margin-top: 10px;
    margin-top: 2.6666666667vw;
    text-align: center;
  }
}

.login-page form .form-group .forgot-link a,
.success-msg-page form .form-group .forgot-link a,
.forgot-password-page form .form-group .forgot-link a,
.reset-password-page form .form-group .forgot-link a,
.login-page form .form-group .have-password a,
.success-msg-page form .form-group .have-password a,
.forgot-password-page form .form-group .have-password a,
.reset-password-page form .form-group .have-password a {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #212529;
  text-transform: capitalize;
}

.login-page form .form-group .forgot-link a:hover,
.success-msg-page form .form-group .forgot-link a:hover,
.forgot-password-page form .form-group .forgot-link a:hover,
.reset-password-page form .form-group .forgot-link a:hover,
.login-page form .form-group .have-password a:hover,
.success-msg-page form .form-group .have-password a:hover,
.forgot-password-page form .form-group .have-password a:hover,
.reset-password-page form .form-group .have-password a:hover {
  color: #3d3a7f;
}

.login-page form .submit-btn,
.success-msg-page form .submit-btn,
.forgot-password-page form .submit-btn,
.reset-password-page form .submit-btn {
  margin: auto;
  display: block;
  width: 200px;
  margin-top: 30px;
}

.login-page .form-bottom,
.success-msg-page .form-bottom,
.forgot-password-page .form-bottom,
.reset-password-page .form-bottom {
  text-align: center;
}

.login-page .form-bottom p,
.success-msg-page .form-bottom p,
.forgot-password-page .form-bottom p,
.reset-password-page .form-bottom p {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 600;
  color: #212529;
}

.login-page .form-bottom p a,
.success-msg-page .form-bottom p a,
.forgot-password-page .form-bottom p a,
.reset-password-page .form-bottom p a {
  color: #3d3a7f;
}

.login-page .form-bottom p a:hover,
.success-msg-page .form-bottom p a:hover,
.forgot-password-page .form-bottom p a:hover,
.reset-password-page .form-bottom p a:hover {
  text-decoration: underline;
}

.reset-password-page form .submit-btn {
  width: 262px;
}

.success-msg-page .form-heading .success-title {
  color: #3d3a7f;
}

.success-msg-page form .success-msg {
  font-size: 13px;
  font-size: 0.8125rem;
  text-align: center;
  color: #212529;
  font-weight: 500;
  line-height: 25px;
  max-width: 380px;
  margin: auto;
}

.success-msg-page form .success-msg a {
  color: #3d3a7f;
}

.menu-toggle {
  position: absolute;
  z-index: 1002;
  box-shadow: none;
  border-radius: 0;
  padding: 7px 14px;
  top: 0px;
  right: -36px;
  background-color: #3d3a7f;
  color: #fff;
  outline: none !important;
  border: none;
}

@media (max-width: 575.98px) {
  .menu-toggle {
    top: 12px;
  }
}

.menu-toggle[aria-expanded=true] {
  background-color: #302e6f;
}

.menu-toggle[aria-expanded=true] .navbar-toggler-icon:before {
  font-size: 24px;
  content: "";
}

.menu-toggle .navbar-toggler-icon {
  float: left;
  width: auto;
  height: auto;
  padding: 0;
  font-size: 20px;
}

.menu-toggle .navbar-toggler-icon:before {
  font-weight: 900;
  content: "";
  font-family: "Font Awesome 5 Free";
}

@media (min-width: 992px) {
  .menu-toggle {
    display: none;
  }
}

.sidebar {
  display: block !important;
  overflow: visible !important;
  width: 300px;
  background-color: #302e6f;
  position: relative;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
}

@media (max-width: 991.98px) {
  .sidebar {
    left: -300px;
    -webkit-transition: left 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
    -moz-transition: left 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
    -ms-transition: left 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
    -o-transition: left 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
    transition: left 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
    top: 65px;
    height: calc(100% - 65px);
  }

  .sidebar.show {
    left: 0;
    display: block !important;
    -webkit-transition: left 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
    -moz-transition: left 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
    -ms-transition: left 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
    -o-transition: left 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
    transition: left 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
  }
}

.sidebar .sidebar-sticky {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.sidebar .logo {
  text-align: center;
  margin-top: 70px;
  margin-top: 4.8611111111vw;
}

@media (max-width: 991.98px) {
  .sidebar .logo {
    display: none;
  }
}

.sidebar .logo a {
  text-decoration: none;
}

.sidebar .logo img {
  width: 100px;
}

.sidebar .logo p {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  max-width: 200px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 20px;
}

.sidebar .logo p:hover {
  color: #fd7e14;
}

.sidebar ul.nav {
  padding-top: 17px;
  padding-top: 1.1805555556vw;
  padding-bottom: 17px;
  padding-bottom: 1.1805555556vw;
  display: block;
}

.sidebar ul.nav li.active a {
  color: #fd7e14;
}

.sidebar ul.nav li.active a:before {
  content: "";
  width: 4px;
  height: 100%;
  display: inline-block;
  background-color: #fd7e14;
  position: absolute;
  left: 0;
  top: 0;
}

.sidebar ul.nav li.active li.active a {
  color: #fd7e14;
}

.sidebar ul.nav li.active li a {
  color: #fff;
}

.sidebar ul.nav li.active li a:before {
  display: none;
}

.sidebar ul.nav li.active li a:hover {
  color: #fd7e14;
}

.sidebar ul.nav li a {
  padding-left: 30px;
  padding-left: 2.0833333333vw;
  padding-right: 30px;
  padding-right: 2.0833333333vw;
  margin: 12px 0;
  color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  text-transform: uppercase;
  position: relative;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.sidebar ul.nav li a:hover {
  color: #fd7e14;
}

.sidebar ul.nav li a i {
  margin-right: 20px;
}

.sidebar ul.nav li a[aria-expanded=false]:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  float: right;
}

.sidebar ul.nav li a[aria-expanded=true]:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  float: right;
}

.sidebar ul.nav li .sub-menu {
  background: #302e6f;
}

.sidebar ul.nav li .sub-menu li a {
  margin: 0px;
  text-transform: none;
  font-weight: 400;
}

.sidebar .bottom-link-sidebar {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  background: #302e6f;
  text-align: center;
}

.sidebar .bottom-link-sidebar a {
  color: #fff;
  padding: 10px 20px;
  display: inline-block;
}

.sidebar .bottom-link-sidebar a i {
  padding-right: 5px;
}

.sidebar .bottom-link-sidebar a:hover {
  text-decoration: none;
  color: #fd7e14;
}

.decision-tree-container {
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  position: relative;
}

.decision-tree-container .decision-tree-content {
  position: relative;
  display: inline-block;
  cursor: grab;
}

.decision-tree-container .decision-tree-content .decision-tree-group {
  display: flex;
  align-items: flex-start;
}

.decision-tree-container .decision-tree-content .decision-tree-group .decision-tree-group-container {
  display: inline-flex;
  flex-direction: column;
}

.decision-tree-container .decision-tree-content .decision-tree-group .decision-tree-group-column .column-item {
  font-size: 14px;
  width: 250px;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: #efefef;
  border: 1px solid #efefef;
}

.decision-tree-container .decision-tree-content .decision-tree-group .decision-tree-group-column .column-item.selected {
  border-color: #9c9ad4;
}

.decision-tree-container .decision-tree-content .decision-tree-group .decision-tree-group-column .column-item.current {
  background-color: #ebe9ff;
}

.decision-tree-container .decision-tree-content .decision-tree-group .decision-tree-group-column .column-item.preselected {
  border-color: #f0a27a !important;
}

.decision-tree-container .decision-tree-content .decision-tree-group .decision-tree-group-column .column-item.proposition .proposition-message textarea {
  width: 100%;
  height: 130px;
  cursor: grab;
}

.decision-tree-container .decision-tree-content .decision-tree-group .decision-tree-group-column .column-item.outcome {
  cursor: pointer;
}

.decision-tree-container .decision-tree-content .decision-tree-group .decision-tree-group-column .column-item .control-panel {
  text-align: right;
}

.decision-tree-container .decision-tree-content .decision-tree-group .decision-tree-group-column .column-item .el-button {
  color: #9c9ad4;
}

.decision-tree-container .decision-tree-content .add-root-placeholder {
  width: 250px;
  padding: 10px;
  color: #9c9ad4;
  cursor: pointer;
}

